// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-theme-community-src-pages-events-tsx": () => import("./../../../node_modules/gatsby-theme-community/src/pages/events.tsx" /* webpackChunkName: "component---node-modules-gatsby-theme-community-src-pages-events-tsx" */),
  "component---node-modules-gatsby-theme-community-src-pages-index-tsx": () => import("./../../../node_modules/gatsby-theme-community/src/pages/index.tsx" /* webpackChunkName: "component---node-modules-gatsby-theme-community-src-pages-index-tsx" */),
  "component---node-modules-gatsby-theme-community-src-templates-event-tsx": () => import("./../../../node_modules/gatsby-theme-community/src/templates/event.tsx" /* webpackChunkName: "component---node-modules-gatsby-theme-community-src-templates-event-tsx" */),
  "component---node-modules-gatsby-theme-community-src-templates-plain-mdx-tsx": () => import("./../../../node_modules/gatsby-theme-community/src/templates/plain_mdx.tsx" /* webpackChunkName: "component---node-modules-gatsby-theme-community-src-templates-plain-mdx-tsx" */)
}

